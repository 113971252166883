<template>
    <article class="card-featured-item" :class="isForSlider ? 'w-72 p-2 xs:w-[326px]' : 'px-3 py-2'">
        <div>
            <div v-if="$isMega" class="card-featured-item__heat">
                <DWidgetHeat
                    class="card-featured-item__heat-counter"
                    text-style="text-xs"
                    icon-style="w-3 h-3"
                    :count="info.temperature_sum || 0"
                />
            </div>
            <NuxtLink
                v-if="!info.without_detail || !info.is_admin"
                no-prefetch
                :to="`/${isCoupon ? $lang.routes.coupons : $lang.routes.offers}/${info.slug}`"
                rel="follow"
            >
                <picture class="card-featured-item__image">
                    <img
                        :src="info.featured_image || defaultImage[info.type]"
                        :alt="info.title"
                        :title="info.title"
                        width="10px"
                        height="10px"
                        loading="lazy"
                        :onerror="`this.onerror=null;this.src='${defaultImage[info.type]}'`"
                    />
                </picture>
            </NuxtLink>
            <div v-else @click="() => openCoupon()" class="cursor-pointer">
                <picture class="card-featured-item__image">
                    <img
                        :src="info.featured_image || defaultImage[info.type]"
                        :alt="info.title"
                        :title="info.title"
                        width="10px"
                        height="10px"
                        loading="lazy"
                        :onerror="`this.onerror=null;this.src='${defaultImage[info.type]}'`"
                    />
                </picture>
            </div>
            <p
                class="card-featured-item__type"
                :class="isCoupon ? 'text-site-primary' : 'text-site-secondary'"
            >
                {{ isCoupon ? $lang.components.cardFeatured.coupon : $lang.components.cardFeatured.offer }}
            </p>
            <NuxtLink
                v-if="!info.without_detail"
                no-prefetch
                :to="`/${isCoupon ? $lang.routes.coupons : $lang.routes.offers}/${info.slug}`"
                class="card-featured-item__title"
                rel="follow"
            >
                <h2>
                    {{ info.title }}
                </h2>
            </NuxtLink>
            <div class="cursor-pointer" v-else @click="() => openCoupon()">
                <h3>
                    {{ info.title }}
                </h3>
            </div>
        </div>
        <div v-if="showNewPrice" class="card-featured-item__price">
            <span class="price">
                {{ newPrice }}
            </span>
            <span v-if="info.comments_count" class="comments">
                <img :src="$assets.gray.comment" alt="comment icon" />
                {{ info.comments_count }}
            </span>
        </div>
    </article>
</template>

<script lang="ts">
import type { Models } from '~/types/models'
import { useVariantsDictionary } from '~/composables/VariantsDictionary'

export default defineComponent({
    name: 'CardFeaturedDiscount',
    props: {
        info: {
            type: Object as PropType<Models.Discount>,
            required: true,
        },
        isForSlider: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const { defaultImages } = useVariantsDictionary()
        return {
            defaultImage: defaultImages,
        }
    },
    computed: {
        isOffer(): boolean {
            return this.info.type === 'offer'
        },
        isCoupon(): boolean {
            return this.info.type === 'coupon'
        },
        showNewPrice(): boolean {
            return (
                (this.isOffer && !!this.info.new_price) ||
                (this.isCoupon &&
                    (((this.info.discount_type === 'precio' || this.info.discount_type === 'porcentaje') &&
                        this.info.discount_value !== '0') ||
                        this.info.discount_type === 'envío gratis'))
            )
        },
        newOfferPrice(): string {
            return this.info.new_price === '0' ? 'GRATIS' : this.$lang.symbol + this.info.new_price + ' OFF'
        },
        newCouponPrice(): string {
            return this.info.discount_type === 'envío gratis'
                ? this.$lang.components.cardFeatured.free_ship
                : this.info.discount_type === 'precio'
                  ? this.$lang.symbol + this.info.discount_value + ' OFF'
                  : this.info.discount_value + '% OFF'
        },
        newPrice(): string {
            return (this.isOffer && this.newOfferPrice) || (this.isCoupon && this.newCouponPrice) || ''
        },
    },
    methods: {
        openCoupon() {
            this.$openers.openDiscount({
                id: this.info.id,
                slug: this.info.slug,
                type: this.info.type,
            })
        },
    },
})
</script>

<style lang="postcss" scoped>
.card-featured-item {
    @apply relative flex h-full flex-col justify-between space-y-2 overflow-hidden rounded-xl border bg-white;
    &__heat {
        @apply absolute right-0 top-0 rounded-bl-2xl border-b border-l bg-white py-1 pl-2 pr-3;
    }
    &__image {
        @apply block h-28;
        img {
            @apply h-full w-full object-contain;
        }
    }
    &__type {
        @apply mt-2 text-xs font-semibold;
    }
    &__title {
        @apply line-clamp-2 text-base font-medium text-gray-800 hover:underline;
    }
    &__price {
        @apply flex items-center justify-between space-x-2 text-xl;
        .comments {
            @apply flex items-center gap-1 text-xs font-medium text-gray-800 lg:text-sm;
            img {
                @apply h-3 w-3 lg:h-4 lg:w-4;
            }
        }
        .price {
            @apply font-semibold text-site-primary;
        }
        .price-from {
            @apply text-gray-800 line-through;
        }
        .price-percent {
            @apply font-semibold text-site-primary;
        }
        .free-ship {
            @apply inline-flex flex-nowrap space-x-1 text-sm text-gray-800;
            img {
                @apply h-3 self-center;
            }
        }
    }
}
</style>
